class GenerateOpenAPIRequest {
    type;
    path;
    summary;
    requestBody;
    responses;

    constructor(type, path, summary, requestBody, responses) {
        this.#setType(type);
        this.#setPath(path);
        this.#setSummary(summary);
        this.#setRequestBody(requestBody);
        this.#setResponses(responses);
    }

    #setType(type) {
        this.#checkNull("type", type);
        this.type = type;
    }

    #setPath(path) {
        this.#checkNull("path", path);
        this.path = path;
    }

    #setSummary(summary) {
        this.#checkNull("summary", summary);
        this.summary = summary;
    }

    #setRequestBody(requestBody) {
        this.requestBody = requestBody;
    }

    #setResponses(responses) {
        this.responses = responses;
    }

    #checkNull(fieldName, fieldValue) {
        if (fieldValue == null) {
            throw new Error(fieldName + " must not be null");
        }
    }
}

export default GenerateOpenAPIRequest;