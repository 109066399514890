import React, {useEffect, useState} from 'react';
import ResponseBlock from "./ResponseBlock";
import {ResponseBlockModel} from "./ResponseBlockModel";
import Button from "../../components/button/Button";
import {getCustomerInfo} from "../../service/customerService";
import GenerateOpenAPIRequest from "../../service/generate/GenerateOpenAPIRequest";
import {generateOpenAPI} from "../../service/generate/GenerateService";
import InputWithError from "../../components/input/InputWithError";

class GenerateResponse {
    constructor(balance, openAPI) {
        this.balance = balance;
        this.openAPI = openAPI;
    }
}

const OpenApiGenerator = () => {
    const [method, setMethod] = useState('POST');
    const [path, setPath] = useState('');
    const [summary, setSummary] = useState('My awesome method');
    const [requestBody, setRequestBody] = useState('');
    const [openAPIResponse, setOpenAPIResponse] = useState(new GenerateResponse(0, ''));
    const [blocks, setBlocks] = useState([new ResponseBlockModel(Date.now(), "200", "{}")]);
    const [pathError, setPathError] = useState('');

    const validatePath = (value) => {
        if (!value.startsWith('/')) {
            return "Path must start with a '/'.";
        }
        if (/[^a-zA-Z0-9/_-{}]/.test(value)) {
            return "Only alphanumeric, '/', '_','{','}' and '-' are allowed.";
        }
        return '';
    };

    const handlePathChange = (e) => {
        const newPath = e.target.value;
        setPath(newPath);
        const error = validatePath(newPath);
        setPathError(error);
    };

    useEffect(() => {
        getCustomerInfo().then((response) => {
            setOpenAPIResponse(new GenerateResponse(response.balance, openAPIResponse.openAPI));
        });
    }, [openAPIResponse.openAPI]);

    // Add a new block
    const handleAddBlock = () => {
        const newBlock = new ResponseBlockModel(Date.now()); // Create a new model instance
        setBlocks([...blocks, newBlock]);
    };

    // Remove a block by its ID
    const handleRemoveBlock = (id) => {
        setBlocks(blocks.filter((block) => block.id !== id));
    };

    // Update a block in the list
    const handleUpdateBlock = (updatedBlock) => {
        setBlocks(
            blocks.map((block) =>
                block.id === updatedBlock.id ? updatedBlock : block
            )
        );
    };

    const handleGenerateV2 = async () => {
        console.log(blocks)
        const responses = blocks.length === 0 ? undefined : blocks.map((block) => (
            {
                code: block.code,
                json: block.responseBody,
            }));

        let requestBodyObj = undefined;

        if (requestBody == null || requestBody.length > 0) {
            requestBodyObj = requestBody;
        }

        if (pathError !== '' || path === '') {
            setPathError(validatePath(path));
            return
        }

        const request = new GenerateOpenAPIRequest(
            method,
            path,
            summary.trim() || "My awesome method",
            requestBodyObj,
            responses,
        )
        await generateOpenAPI(request).then((response) => {
            setOpenAPIResponse(new GenerateResponse(response.balance, response.openAPI))
        })
    }

    return (
        <div className="container">
            <h1>Easy openAPI generator</h1>
            <div className="line">
                <select value={method} onChange={(e) => setMethod(e.target.value)}
                        className="dropdown line-element">
                    <option value="POST">POST</option>
                    <option value="GET">GET</option>
                    <option value="PUT">PUT</option>
                    <option value="PATCH">PATCH</option>
                    <option value="DELETE">DELETE</option>
                    <option value="OPTION">OPTION</option>
                </select>

                <InputWithError
                    value={path}
                    onChange={(e) => handlePathChange(e)}
                    placeholder="/api/v1/example"
                    error={pathError}
                />

                <InputWithError
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    placeholder="/api/v1/example"
                    error=""
                />
            </div>

            <div className="line">
                <div className='vertical-block'>
                    <label>Request Body:</label>
                    <textarea
                        value={requestBody}
                        onChange={(e) => setRequestBody(e.target.value)}
                        className="req-resp-textarea"
                        placeholder='{ "key": "value" }'
                    />
                </div>
            </div>

            <div className='vertical-block'>
            <div>
                    {blocks.map((block) => <ResponseBlock
                        key={block.id}
                        model={block}
                        onRemove={handleRemoveBlock}
                        onUpdate={handleUpdateBlock}
                    />)}
                </div>
                <div>
                    <Button color="green" text="Add response" onClick={handleAddBlock}/>
                </div>
            </div>


            <div className="vertical-block center">
                <div className="line"><label>Balance: {openAPIResponse.balance}</label></div>
                <div className="line"><Button text="Generate" onClick={handleGenerateV2}/></div>
            </div>

            <div className="line">
        <textarea
            value={openAPIResponse.openAPI}
            readOnly
            className="textarea response-textarea"
            placeholder='Openapi will be here'
        />
            </div>
        </div>
    );
};

export default OpenApiGenerator;
