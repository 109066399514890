import React from 'react';
import './InputWithError.css';

const InputWithError = ({ value, onChange, placeholder, error }) => {
    return (
        <div className="input-with-error">
            <input
                type="text"
                value={value}
                onChange={onChange}
                className={`input ${error ? 'error' : ''}`}
                placeholder={placeholder}
            />
            <div className="error-message">{error}</div>
        </div>
    );
};

export default InputWithError;
