import React, {useState} from 'react';
import axios from 'axios';

import './Registration.css'
import {useNavigate} from "react-router-dom";

function Registration() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
    });

    const [registrationError, setRegistrationError] = useState(null);
    const navigate = useNavigate()

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Submit form data to your API or backend here
        const url = 'https://openapi.ilinykh.org/api/public/v1/auth/register';

        try {
            await axios
                .post(url, formData, {
                    headers: {'Content-Type': 'application/json'}
                })
            navigate('/login');
        } catch (error) {
            switch (error.response.status) {
                case 400:
                    setRegistrationError("Incorrect registration data");
                    break;
                default:
                    setRegistrationError("Technical error");
                    break;
            }
        }
    };

    return (
        <div className='signup-container'>
            <form className='signup-card' onSubmit={handleSubmit}>
                <h2>Sign up</h2>
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="firstName"
                    placeholder="First name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
                {registrationError!=='' && (<label className='error-message'>{registrationError}</label>)}
                <button type="submit">Register</button>
            </form>
        </div>
    );
}

export default Registration;
