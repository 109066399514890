import React, { useState } from 'react';
import { fetchTokens } from "../service/authService";
import {useNavigate} from "react-router-dom";

import './LoginPage.css';

const LoginPage = ({onAuthChange}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loginFailed, setLoginFailed] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            await fetchTokens(email, password); // Ensure this resolves correctly
            navigate('/generator'); // Navigate only after successful login
            onAuthChange(true);
        } catch (error) {
            console.error('Error during login:', error);
            setLoginFailed(true);
            onAuthChange(false);
        }
    }

    return (
        <div className="login-container">
            <form className="login-card" onSubmit={handleLogin}>
                <h2 className='login-card-item'>Welcome Back</h2>
                <input
                    type="email"
                    placeholder="Email"
                    className="login-input login-card-item"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    className="login-input login-card-item"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                {(loginFailed) &&
                    <label className="error-message">Incorrect Email or Password</label>
                }
                <button type="submit" className="login-button login-card-item">Login</button>
            </form>
        </div>
    )
}

export default LoginPage;