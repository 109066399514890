import React from 'react';
import './MainPage.css'; // Import the CSS file
import openapiExample from '../resources/openapi_example.png';
import {useNavigate} from "react-router-dom";
import Button from "../components/button/Button";


const MainPage = () => {
    const navigate = useNavigate();

    const handleSignIn = () => {
        navigate('/register')
    }

    return (
        <div className="white-container">
            <div className="white-content">
                <div className="white-text">
                    <h1 className="white-title">
                        Create <span className="blue-highlight">OpenAPI Documentation</span> Instantly
                    </h1>
                    <p className="white-subtitle">
                        Input your request and response to generate OpenAPI documentation in seconds.
                    </p>

                    <div className="white-features">
                        <p className="white-feature-title">Perfect for:</p>
                        <ul className="white-feature-list">
                            <li className="white-feature-item">System Analysts</li>
                            <li className="white-feature-item">Solution Architects</li>
                            <li className="white-feature-item">Software Developers</li>
                        </ul>
                    </div>

                    <Button text="Sign Up" color="blue" onClick={handleSignIn}/>
                </div>

                <div className="white-image-container">
                    <img src={openapiExample} alt="OpenAPI Generation example" className="white-image" />
                </div>
            </div>
        </div>
    );
};

export default MainPage;
