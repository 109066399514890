import {apiClient} from "./apiClient";
import {getAccessToken} from "./tokenService";
import {CustomerInfo} from "./CustomerInfo";


export const getCustomerInfo = async () => {
    try {
        const response = await apiClient.get('https://openapi.ilinykh.org/api/v1/customer', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getAccessToken(),
            }
        })

        const { data } = response;

        return new CustomerInfo(data.firstName, data.lastName, data.email, data.balance)
    } catch (error) {
        throw new Error(error.message);
    }
};