import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";

const EmailVerificationPage = () => {
    const { id } = useParams();
    const [verificationErrorMessage, setVerificationErrorMessage] = useState(null);

    useEffect(() => {
       if (id) {
           verifyEmail(id);
       }
       else {
           setVerificationErrorMessage("Invalid verification link")
       }
    }, []);

    const verifyEmail = async (id) => {
        const url = "https://openapi.ilinykh.org/api/public/v1/auth/verification/" + id;

        try {
            await axios.put(url);
            setVerificationErrorMessage("Email successfully verified")
        }
        catch (error) {
            if (error.response.status === 400) {
                setVerificationErrorMessage(error.response.data.message)
            }
            else {
                setVerificationErrorMessage("Unpredicted error. Contact to support: support@ilinykh.org");
            }
        }

    };

    return (
        <div className="container">
            <label>{verificationErrorMessage}</label>
        </div>
    );
};
export default EmailVerificationPage;