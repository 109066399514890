// ResponseBlock.js
import React, {useState} from "react";
import "./ResponseBlock.css";
import Button from "../../components/button/Button";

const ResponseBlock = ({ model, onRemove, onUpdate }) => {
    const handleCodeChange = (e) => {
        model.setCode(e.target.value); // Update the model directly
        onUpdate(model); // Notify the parent of the update
    };

    const handlePayloadChange = (e) => {
        model.setResponseBody(e.target.value); // Update the model directly
        onUpdate(model); // Notify the parent of the update
    };

    const [contentType, setContentType] = useState('application/json');

    return (
        <div className="response-block">
            <div className="vertical-block">
                <div className="response-block-meta-line">
                    <input
                        type="number"
                        placeholder="Response code"
                        value={model.code}
                        onChange={handleCodeChange}
                    />
                    <select disabled={true} value={contentType} onChange={(e) => setContentType(e.target.value)}
                            className="content-type">
                        <option value="application/json">application/json</option>
                    </select>
                </div>
                <textarea
                    value={model.responseBody}
                    onChange={handlePayloadChange}
                    className="req-resp-textarea"
                    placeholder='{ "key": "value" }'
                />
            </div>
            <div className="vertical-block">
                <div>
                    <Button color="red" text="Remove" onClick={() => onRemove(model.id)}/>
                </div>
            </div>
        </div>
    );
};

export default ResponseBlock;
