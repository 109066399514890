import {apiClient} from "../../service/apiClient";
import {getAccessToken} from "../../service/tokenService";
import {useEffect, useState} from "react";

import "./PorfilePage.css"

const ProfilePage = () => {
    const [customer, setCustomer] = useState({});

    useEffect(() => {
        const url = 'https://openapi.ilinykh.org/api/v1/customer';
        const fetchClient = async () => {
            const { data } = await apiClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + getAccessToken(),
                }
            });
            setCustomer(data)
        }
        fetchClient()
    }, []);


    return (
        <div className="container">
            <div className="profile-line">
                <label>First name: </label>
                <span>{customer.firstName}</span>
            </div>
            <div className="profile-line">
                <label>Last name: </label>
                <span>{customer.lastName}</span>
            </div>
            <div className="profile-line">
                <label>Email: </label>
                <span>{customer.email}</span>
            </div>
            <div className="profile-line">
            <label>Balance: </label>
                <span>{customer.balance}</span>
            </div>
        </div>
    );
};
export default ProfilePage;