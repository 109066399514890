import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Navigate} from 'react-router-dom';


import Registration from './pages/Registration';
import OpenApiGenerator from './pages/generator/OpenApiGenerator';
import TopBar from './components/topbar/TopBar';
import './App.css';
import LoginPage from "./pages/LoginPage";
import {isAuthenticated} from "./service/authService";
import {useEffect, useState} from "react";
import MainPage from "./pages/MainPage";
import ProfilePage from "./pages/profile/ProfilePage";
import EmailVerificationPage from "./pages/emailverification/EmailVerificationPage";

const ProtectedRoute = ({isRegistered, children}) => {
    if (!isRegistered) {
        return <Navigate to="/login"/>;
    }
    return children;
};

// Public route component to redirect authenticated users
const PublicRoute = ({isRegistered, children}) => {
    if (isRegistered) {
        return <Navigate to="/generator"/>;
    }
    return children;
};

function App() {
    const [isAuth, setIsAuth] = useState(isAuthenticated()); // State for auth status

    // Monitor authentication state changes if needed (e.g., token storage)
    useEffect(() => {
        async function checkAuthentication() {
            const authStatus = await isAuthenticated();
            setIsAuth(authStatus);
        }

        checkAuthentication();
    }, []);

    return (
        <Router>
            <TopBar onAuthChange={setIsAuth} isAuth={isAuth}/>
            <div>
                <Routes>
                    <Route path="/" element={
                        <MainPage/>
                    }/>

                    <Route path="/register" element={
                        <PublicRoute isRegistered={isAuth}>
                            <Registration/>
                        </PublicRoute>
                    }/>

                    <Route path="/generator" element={
                        <ProtectedRoute isRegistered={isAuth}>
                            <OpenApiGenerator/>
                        </ProtectedRoute>
                    }/>

                    <Route path="/login" element={
                        <PublicRoute isRegistered={isAuth}>
                            <LoginPage onAuthChange={setIsAuth}/>
                        </PublicRoute>
                    }/>

                    <Route path="/profile" element={
                        <ProtectedRoute isRegistered={isAuth}>
                            <ProfilePage/>
                        </ProtectedRoute>
                    }/>

                    <Route path="/emailverification/:id" element={<EmailVerificationPage/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
