import {Token} from "./Token";
import {refreshTokens} from "./authService";

export function getAccessToken() {
    const tokenObj = localStorage.getItem("accessToken");
    if (!tokenObj) {
        return null;
    }

    return JSON.parse(tokenObj).token;
}