import React from 'react';
import './Button.css';

const Button = ({ text, onClick, color = 'blue' }) => {
    return (
        <button className={`button button-${color}`} onClick={onClick}>
            {text}
        </button>
    );
};

export default Button;