import {jwtDecode} from "jwt-decode";

export class Token {
    constructor(token) {
        this.token = token;
    }

    isValid() {
        try {
            const decodedToken = jwtDecode(this.token);
            const currentTime = Date.now() / 1000; // Current time in seconds
            return decodedToken.exp > currentTime; // Check if token is expired
        } catch (error) {
            return false; // If token is invalid or decoding fails
        }
    }
}